import { gql } from '@apollo/client';

// Define query
export const GET_SLOTS_BY_PAGINATION = gql`
  query ($accessToken: String!, $storeCode: String, $channelId: ID, $offset: Float!, $limit: Float!) {
    slotsByPagination(accessToken: $accessToken, storeCode: $storeCode, channelId: $channelId, offset: $offset, limit: $limit) {
      total
      slots {
        id
        capacity
        availableCapacity
        date
        reserved
        type {
          storeCode
          id
          from
          to
          isActive
          isSameDay
          minPrepTime
          sameDayCapacity
          channel {
            id
            channel_name
          }
        }
      }
    }
  }
`;

export const GET_SLOT_BY_ID = gql`
  query ($id: ID!, $accessToken: String!) {
    slotById(id: $id, accessToken: $accessToken) {
      id
      date
      capacity
      availableCapacity
      reserved
      type {
        id
        from
        to
        storeCode
        isActive
        channel {
          id
          channel_name
        }
      }
    }
  }
`;
