import moment from 'moment';
import { DATE_FORMAT, DB_TIME_FORMAT, THAILAND_OFFSET_TIME, TIME_FORMAT } from '.';
import { Slot } from '../types/slot';
import { SlotTemplate } from '../types/slot-template';
import { slotTemplates } from '../screens/time-slot-template/__mock__/mock-data';

export const TimeSlotColumns = [
  {
    field: 'storeCode',
    header: 'Store Id',
    className: 'text-center pl-10',
    template: (slot: Slot) => slot.type.storeCode
  },
  {
    field: 'channel',
    header: 'Channel',
    className: 'text-center',
    template: (slot: Slot) => slot?.type?.channel?.channel_name || '-'
  },
  {
    field: 'date',
    header: 'Date',
    template: (slot: Slot) => moment(slot.date).utcOffset(THAILAND_OFFSET_TIME).format(DATE_FORMAT)
  },
  {
    field: 'from',
    header: 'From',
    template: (slot: Slot) => moment(slot.type.from, DB_TIME_FORMAT).format(TIME_FORMAT)
  },
  {
    field: 'to',
    header: 'To',
    template: (slot: Slot) => moment(slot.type.to, DB_TIME_FORMAT).format(TIME_FORMAT)
  },
  {
    field: 'capacity',
    header: 'Maximum capacity'
  },
  {
    field: 'availableCapacity',
    header: 'Available capacity'
  },
  {
    field: 'reserved',
    header: 'Reserved'
  },
  {
    field: 'type.isActive',
    header: 'Active',
    template: (slot: Slot) => String(slot.type.isActive)
  },
  {
    field: 'type.isSameDay',
    header: 'Same day slot',
    template: (slot: Slot) => String(slot.type.isSameDay || false)
  },
  {
    field: 'id',
    header: 'Slot Id',
    className: 'text-start pl-10'
  }
];

export const TimeSlotTemplateColumns = [
  {
    field: 'storeCode',
    header: 'Store Id',
    className: 'text-center'
  },
  {
    field: 'isSpecialSlot',
    header: 'Time-slot type',
    className: 'text-center',
    template: (slotTemplate: SlotTemplate) => (slotTemplate?.isSpecialSlot ? 'Special' : 'Normal')
  },
  {
    field: 'from',
    header: 'From',
    template: (slotTemplate: SlotTemplate) =>
      moment(slotTemplate.from, DB_TIME_FORMAT).format(TIME_FORMAT)
  },
  {
    field: 'to',
    header: 'To',
    template: (slotTemplate: SlotTemplate) =>
      moment(slotTemplate.to, DB_TIME_FORMAT).format(TIME_FORMAT)
  },
  {
    field: 'maxCapacity',
    header: 'Maximum capacity',
    template: (slotTemplate: SlotTemplate) => {
      return (
        <>
          {slotTemplate?.maxCapacity}
          <img style={{ marginLeft: 5, cursor: 'pointer' }} width="20" src="pen.png" />
        </>
      );
    }
  },
  {
    field: 'sameDayCapacity',
    header: 'Same day capacity',
    template: (slotTemplate: SlotTemplate) => slotTemplate?.sameDayCapacity || '-'
  },
  {
    field: 'percentageForSM1',
    header: 'SM1/AS Allocation',
    template: (slotTemplate: SlotTemplate) => {
      return (
        <>
          {slotTemplate?.percentageForSM1 ? `${slotTemplate?.percentageForSM1}%` : '0%'}
          <img style={{ marginLeft: 5, cursor: 'pointer' }} width="20" src="pen.png" />
        </>
      );
    }
  },
  {
    field: 'isActive',
    header: 'Active'
  }
];
