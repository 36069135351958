import React, { ForwardedRef, forwardRef } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { toast } from 'react-toastify';
import { CustomTimePickerFormControlProps } from '../../types/common';
import 'rc-time-picker/assets/index.css';
import './index.scss';
import { TIME_FORMAT } from '../../constants';
import { TIME_RANGE_ERROR_MSG } from '../../constants/messages';

const now = moment().hour(0).minute(0);

const CustomTimePickerFormControl = forwardRef(
  ({ onChange, value, name }: CustomTimePickerFormControlProps, ref: ForwardedRef<TimePicker>) => (
    <TimePicker
      data-testid={name}
      name={name}
      ref={() => ref}
      value={moment(value || new Date(), TIME_FORMAT)}
      showSecond={false}
      defaultValue={now}
      className="round-input input"
      onChange={(e) => {
        onChange({
          target: {
            name: name,
            value: e ? e.format(TIME_FORMAT) : ''
          }
        });
      }}
      format={TIME_FORMAT}
      use12Hours
      inputReadOnly
    />
  )
);

export const validateTime = (startTime: string, endTime: string): boolean => {
  if (moment(startTime, TIME_FORMAT).diff(moment(endTime, TIME_FORMAT), 'minutes') >= 0) {
    toast.error(TIME_RANGE_ERROR_MSG);
    return false;
  }
  return true;
};
export default CustomTimePickerFormControl;
