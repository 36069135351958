import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/loading-button';
import {
  SlotTemplate,
  CreateUpdateSlotTemplateProps,
  SlotTemplateForm
} from '../../types/slot-template';
import './index.scss';
import CustomTimePickerFormControl, { validateTime } from '../../components/time-picker';
import { withCookies } from 'react-cookie';
import { GET_MASTER_SLOT_TEMPLATE_BY_ID } from '../../graphql/queries/slot-template';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DB_TIME_FORMAT,
  DEFAULT_TIME_SLOT_TEMPLATE,
  FORBIDDEN_RESOURCE_ERROR,
  TIME_FORMAT
} from '../../constants';
import {
  CREATE_SLOT_TEMPLATE,
  UPDATE_MASTER_SLOT_TEMPLATE_BY_ID
} from '../../graphql/mutations/slot-template';
import { GET_ALL_STORES } from '../../graphql/queries/store';
import { Store } from '../../types/store';
import moment from 'moment';
import { errorEvent } from '../../utils/log-event';
import {
  TIME_SLOT_TEMPLATE_CREATE_MSG,
  TIME_SLOT_TEMPLATE_UPDATE_MSG,
  WARNING_FORM_NOT_UPDATE
} from '../../constants/messages';
import { GET_ALL_CHANNELS } from '../../graphql/queries/channel';

export function CreateUpdateSlotTemplate({ cookies, setUser }: CreateUpdateSlotTemplateProps) {
  const accessToken = cookies.get('token');
  const location = useLocation();
  const navigate = useNavigate();
  const { slotTemplateId } = useParams();
  const [updateSlotTemplateById, { loading: isUpdating, error: updateError }] = useMutation(
    UPDATE_MASTER_SLOT_TEMPLATE_BY_ID
  );
  const [createSlotTemplate, { loading: isCreating, error: createError }] =
    useMutation(CREATE_SLOT_TEMPLATE);
  const [getTimeSlotById, { loading: isFetching, error }] = useLazyQuery(
    GET_MASTER_SLOT_TEMPLATE_BY_ID
  );
  const [getAllStores, { data: stores, loading: isStoreFetching, error: storeError }] =
    useLazyQuery(GET_ALL_STORES);
  const [getAllChannels, { data: allChannels, loading: isChannelsFetching, error: channelsError }] =
    useLazyQuery(GET_ALL_CHANNELS);
  const [formValues, setProfileFormValue] = useState<Partial<SlotTemplateForm>>(
    DEFAULT_TIME_SLOT_TEMPLATE
  );

  const [capacities, setCapacities] = useState({
    same_day_sm1: 0,
    same_day_mpro: 0,
    next_day_sm1: 0,
    next_day_mpro: 0
  });

  useEffect(() => {
    const onInitComponent = async () => {
      try {
        if (slotTemplateId) {
          const { data: slotTemplate } = await getTimeSlotById({
            variables: {
              id: slotTemplateId,
              accessToken
            }
          });
          if (slotTemplate) {
            setProfileFormValue({
              from: slotTemplate?.fetchMasterSlotById.from,
              to: slotTemplate?.fetchMasterSlotById.to,
              isActive: slotTemplate?.fetchMasterSlotById.isActive,
              maxCapacity: slotTemplate?.fetchMasterSlotById?.maxCapacity,
              storeCode: slotTemplate?.fetchMasterSlotById?.storeCode,
              oldSlotTemplate: slotTemplate?.fetchMasterSlotById,
              isSameDay: slotTemplate?.fetchMasterSlotById?.isSameDay,
              minPrepTime: slotTemplate?.fetchMasterSlotById?.minPrepTime,
              sameDayCapacity: slotTemplate?.fetchMasterSlotById?.sameDayCapacity,
              isSpecialSlot: slotTemplate?.fetchMasterSlotById?.isSpecialSlot,
              percentageForSM1: slotTemplate?.fetchMasterSlotById?.percentageForSM1,
              updateSlots: true
            });
            handleCapacities(slotTemplate.fetchMasterSlotById?.sameDayCapacity);
          }
        }
        getAllStores({
          variables: { accessToken }
        });
        getAllChannels({
          variables: {
            accessToken
          }
        });
      } catch (error) {
        errorEvent(error);
      }
    };
    if (accessToken) {
      onInitComponent();
    }
  }, [slotTemplateId, getTimeSlotById, getAllStores, getAllChannels, accessToken]);

  const handleCapacities = (capacity: string) => {
    const sm1Percentage = Number(formValues.percentageForSM1);
    const sameDayCapacity = Number(formValues.sameDayCapacity);
    const maxDayCapacity = Number(formValues?.maxCapacity);

    const sameDaySm1 = Math.ceil((sameDayCapacity || 0) * (sm1Percentage / 100));
    const sameDayMpro = sameDayCapacity - sameDaySm1;
    const nextDaySm1 = Math.ceil((maxDayCapacity || 0) * (sm1Percentage / 100));
    const nextDayMpro = maxDayCapacity - nextDaySm1;

    setCapacities({
      same_day_sm1: sameDaySm1,
      same_day_mpro: sameDayMpro,
      next_day_sm1: nextDaySm1,
      next_day_mpro: nextDayMpro
    });
  };

  useEffect(() => {
    const errorMsg =
      error?.message ||
      updateError?.message ||
      storeError?.message ||
      createError?.message ||
      channelsError?.message;
    if (errorMsg === FORBIDDEN_RESOURCE_ERROR) {
      setUser();
    } else if (errorMsg) {
      toast.error(errorMsg);
    }
  }, [error, updateError, storeError, createError, channelsError, setUser]);

  useEffect(() => {
    {
      handleCapacities(formValues.sameDayCapacity as string);
    }
  }, [formValues]);

  useEffect(() => {
    {
      if (formValues.isSpecialSlot) {
        setFormValueByKeyValue('percentageForSM1', '100');
      }
    }
  }, [formValues.isSpecialSlot]);

  useEffect(() => {
    {
      if (!formValues.isSameDay) {
        setFormValueByKeyValue('sameDayCapacity', '0');
        setCapacities({
          same_day_sm1: 0,
          same_day_mpro: 0,
          next_day_sm1: 0,
          next_day_mpro: 0
        });
      }
    }
  }, [formValues.isSameDay]);

  const setFormValueByKeyValue = (key: string, value: string | boolean) => {
    const nonNegativeValues = ['percentageForSM1', 'maxCapacity', 'sameDayCapacity'];
    if (nonNegativeValues.includes(key)) {
      const isPositive: boolean = /^\d+$/.test(value as string);
      if (isPositive) {
        setProfileFormValue({
          ...formValues,
          ...{
            [key]: value
          }
        });
      }
    } else {
      setProfileFormValue({
        ...formValues,
        ...{
          [key]: value
        }
      });
    }
  };

  const moveToBack = () => {
    toast.success(slotTemplateId ? TIME_SLOT_TEMPLATE_UPDATE_MSG : TIME_SLOT_TEMPLATE_CREATE_MSG);
    navigate('/time-slot-template', {
      state: {
        storeCode: location?.state?.storeCode,
        channelId: location?.state?.channelId
      }
    });
  };

  const isValuesChange = (updateSlotInput: Partial<SlotTemplate>) => {
    if (
      updateSlotInput.from !== formValues.oldSlotTemplate?.from ||
      updateSlotInput.to !== formValues.oldSlotTemplate?.to ||
      updateSlotInput.isActive !== formValues.oldSlotTemplate?.isActive ||
      updateSlotInput.maxCapacity !== formValues.oldSlotTemplate?.maxCapacity ||
      updateSlotInput.storeCode !== formValues.oldSlotTemplate?.storeCode ||
      updateSlotInput.isSpecialSlot !== formValues.oldSlotTemplate?.isSpecialSlot ||
      updateSlotInput.percentageForSM1 !== formValues.oldSlotTemplate?.percentageForSM1 ||
      updateSlotInput.isSameDay !== formValues.oldSlotTemplate?.isSameDay ||
      updateSlotInput.sameDayCapacity !== formValues.oldSlotTemplate?.sameDayCapacity
    ) {
      return true;
    }
    return false;
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    let res;
    e.preventDefault();
    if (!validateTime(formValues.from || '', formValues.to || '')) {
      return;
    }
    try {
      const from = moment(formValues.from, TIME_FORMAT).format(DB_TIME_FORMAT);
      const to = moment(formValues.to, TIME_FORMAT).format(DB_TIME_FORMAT);
      const updateSlotInput = {
        isActive: formValues?.isActive,
        storeCode: formValues?.storeCode,
        to,
        from,
        maxCapacity: parseInt(String(formValues.maxCapacity)),
        updateSlots: formValues.updateSlots,
        isSameDay: formValues?.isSameDay,
        minPrepTime: formValues?.minPrepTime,
        sameDayCapacity: parseInt(String(formValues?.sameDayCapacity)),
        percentageForSM1: parseInt(String(formValues?.percentageForSM1)),
        isSpecialSlot: formValues?.isSpecialSlot
      };
      if (!isValuesChange(updateSlotInput)) {
        toast.warning(WARNING_FORM_NOT_UPDATE);
        return;
      }
      if (slotTemplateId) {
        const { data } = await updateSlotTemplateById({
          variables: {
            accessToken,
            id: slotTemplateId,
            updateMasterSlotInput: updateSlotInput
          }
        });
        res = data;
      } else {
        const { data } = await createSlotTemplate({
          variables: {
            accessToken,
            CreateMasterSlotTypeInput: {
              isActive: formValues?.isActive,
              storeCode: formValues?.storeCode,
              to,
              from,
              maxCapacity: parseInt(String(formValues.maxCapacity)),
              isSameDay: formValues?.isSameDay,
              minPrepTime: formValues?.minPrepTime || '00:00:00',
              sameDayCapacity: parseInt(String(formValues?.sameDayCapacity)),
              percentageForSM1: parseInt(String(formValues?.percentageForSM1)),
              isSpecialSlot: formValues?.isSpecialSlot
            }
          }
        });
        res = data;
      }
      if (res) {
        moveToBack();
      }
    } catch (error) {
      errorEvent(error);
    }
  };

  const onCancel = (): void => {
    navigate('/time-slot-template', {
      state: {
        storeCode: location?.state?.storeCode,
        channelId: location?.state?.channelId
      }
    });
  };

  if (isFetching || isStoreFetching || isChannelsFetching) {
    return (
      <>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
      </>
    );
  }
  return (
    <div className="update-time-slot-container">
      <Form
        onSubmit={handleSubmit}
        className="mt-3"
        data-testid="create-update-time-slot-template-form">
        <h3 className="ps-3 mb-3">
          {slotTemplateId ? 'Update Time-slot Template' : 'Create New Time-slot Template'}
        </h3>
        {slotTemplateId && (
          <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="id">
            <div className="me-3 w-30 label-container">
              <Form.Label>Slot Template Id</Form.Label>
            </div>
            <div className="round-input input value">{slotTemplateId}</div>
          </Form.Group>
        )}

        {/* Store Code */}
        <Form.Group className="d-flex align-items-center gap-5 mb-3" controlId="storeCode">
          <div className="me-3 w-30 label-container">
            <Form.Label>Store Id</Form.Label>
          </div>
          <Form.Select
            className="input"
            value={formValues.storeCode}
            onChange={(e) => setFormValueByKeyValue('storeCode', e.target.value)}
            required
            data-testid="storeCode"
            name="storeCode"
            disabled={!!slotTemplateId}>
            <option value="">Select Store</option>
            {stores?.stores.map(({ id, storeCode }: Store) => (
              <option key={id} value={storeCode}>
                {storeCode}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {/* Slot type Radio button */}
        <Form.Group className="d-flex align-items-center gap-5 mb-2" controlId="isSpecialSlot">
          <div className="me-3 w-30 label-container">
            <Form.Label>Time slot</Form.Label>
          </div>
          <Form.Check
            onChange={() => setFormValueByKeyValue('isSpecialSlot', false)}
            inline
            label="Normal time slot"
            name="isSpecialSlot"
            type="radio"
            checked={!formValues.isSpecialSlot}
          />
          <Form.Check
            inline
            onChange={() => setFormValueByKeyValue('isSpecialSlot', true)}
            label="Special time slot (For SM1 / Assisted sales)"
            name="isSpecialSlot"
            type="radio"
            checked={formValues.isSpecialSlot}
          />
        </Form.Group>
        <div className="d-flex justify-content-center">
          <hr className="w-90" />
        </div>

        {/* % for SM1 */}
        <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="percentageForSM1">
          <div className="me-3 w-30 label-container">
            <Form.Label>% for SM1</Form.Label>
          </div>
          <div className={formValues.isSpecialSlot ? 'w-70' : 'w-30'}>
            <div className={formValues.isSpecialSlot ? 'w-82' : ''}>
              <Form.Control
                className="round-input input w-30"
                value={formValues.isSpecialSlot ? 100 : formValues.percentageForSM1}
                onChange={(e) => setFormValueByKeyValue('percentageForSM1', e.target.value)}
                min={0}
                type="number"
                placeholder="%"
                required
                data-testid="percentageForSM1"
                name="percentageForSM1"
                disabled={formValues.isSpecialSlot}
              />
            </div>
            {formValues.isSpecialSlot && (
              <div className="text-muted">
                For special time slot, 100% of the capacity will be allocated for SM1 / Assisted
                sales
              </div>
            )}
          </div>
        </Form.Group>

        {/* Maximum capacity */}
        <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="maxCapacity">
          <div className="me-3 w-30 label-container">
            <Form.Label>Maximum capacity</Form.Label>
          </div>
          <div className="w-30">
            <Form.Control
              className="round-input input"
              value={formValues.maxCapacity}
              onChange={(e) => setFormValueByKeyValue('maxCapacity', e.target.value)}
              min={1}
              type="number"
              placeholder="Max Capacity"
              required
              data-testid="maxCapacity"
              name="maxCapacity"
            />
          </div>
        </Form.Group>

        {/* Allow same day */}
        <Form.Group
          className="d-flex align-items-center justify-content-start mb-3 gap-5"
          controlId="isActive">
          <div className="me-3 w-30 label-container">
            <Form.Label>Allow SameDay</Form.Label>
          </div>
          <Form.Check
            className="time-input"
            checked={formValues.isSameDay}
            onChange={(e) => setFormValueByKeyValue('isSameDay', e.target.checked)}
            type="checkbox"
            data-testid="isSameDay"
            name="isSameDay"
          />
        </Form.Group>

        {/* Same day capacity */}
        <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="sameDayCapacity">
          <div className="me-3 w-30 label-container">
            <Form.Label>Same day Capacity</Form.Label>
          </div>
          <div className="w-30">
            <Form.Control
              className="round-input input"
              value={formValues.sameDayCapacity}
              onChange={(e) => setFormValueByKeyValue('sameDayCapacity', e.target.value)}
              min={0}
              type="number"
              placeholder="Same day Capacity"
              data-testid="sameDayCapacity"
              disabled={!formValues.isSameDay}
              name="sameDayCapacity"
              required={formValues.isSameDay}
            />
          </div>
        </Form.Group>

        {/* Same day cap for SM1 */}
        <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="sameDayCapacity">
          <div className="me-3 w-30 label-container">
            <Form.Label>Same day Capacity for SM1</Form.Label>
          </div>
          <div className={formValues.isSpecialSlot ? 'w-16' : 'w-70'}>
            <div className="d-flex align-items-center">
              <div className={formValues.isSpecialSlot ? 'w-100 me-3' : 'w-33 me-3'}>
                <Form.Control
                  className="round-input input w-100"
                  value={capacities.same_day_sm1}
                  onChange={(e) => setFormValueByKeyValue('sameDayCapacity', e.target.value)}
                  min={1}
                  type="number"
                  placeholder="N/A"
                  data-testid="sameDayCapacity"
                  disabled={!formValues.isSameDay}
                  readOnly={formValues.isSameDay}
                  name="sameDayCapacitySM1"
                />
              </div>
              {!formValues.isSpecialSlot && (
                <Form.Group
                  className="d-flex align-items-center gap-5  mb-"
                  controlId="sameDayCapacity">
                  {/* <div className="me-3  label-container"> */}
                  <Form.Label>Same day Capacity for MPro</Form.Label>
                  {/* </div> */}
                  <Form.Control
                    className="round-input w-33"
                    value={capacities.same_day_mpro}
                    onChange={(e) => setFormValueByKeyValue('sameDayCapacity', e.target.value)}
                    min={1}
                    type="number"
                    placeholder="N/A"
                    data-testid="sameDayCapacity"
                    disabled={!formValues.isSameDay}
                    readOnly={formValues.isSameDay}
                    name="sameDayCapacityMpro"
                  />
                </Form.Group>
              )}
            </div>
          </div>
        </Form.Group>

        {/* Next day cap for SM1 */}
        <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="sameDayCapacity">
          <div className="me-3 w-30 label-container">
            <Form.Label>Next day Capacity for SM1</Form.Label>
          </div>
          <div className={formValues.isSpecialSlot ? 'w-16' : 'w-70'}>
            <div className="d-flex align-items-center">
              <div className={formValues.isSpecialSlot ? 'w-100 me-3' : 'w-33 me-3'}>
                <Form.Control
                  className="round-input input w-100"
                  value={capacities.next_day_sm1}
                  onChange={(e) => setFormValueByKeyValue('sameDayCapacity', e.target.value)}
                  min={1}
                  type="number"
                  placeholder="N/A"
                  data-testid="sameDayCapacity"
                  readOnly
                  disabled={!formValues.isSameDay}
                  name="nextDayCapacitySM1"
                />
              </div>
              {!formValues.isSpecialSlot && (
                <Form.Group
                  className="d-flex align-items-center gap-5  "
                  controlId="sameDayCapacity">
                  {/* <div className="me-3  label-container"> */}
                  <Form.Label>Next day Capacity for MPro</Form.Label>
                  {/* </div> */}
                  <Form.Control
                    className="round-input w-33"
                    value={capacities.next_day_mpro}
                    onChange={(e) => setFormValueByKeyValue('sameDayCapacity', e.target.value)}
                    min={1}
                    type="number"
                    placeholder="N/A"
                    data-testid="sameDayCapacity"
                    readOnly
                    disabled={!formValues.isSameDay}
                    name="nextDayCapacityMpro"
                  />
                </Form.Group>
              )}
            </div>
          </div>
        </Form.Group>

        {/* Channel dropdown
        <Form.Group className="align-items-center gap-5  mb-3" controlId="channel">
          <div className="me-3 w-30 label-container">
            <Form.Label>Channel</Form.Label>
          </div>
          <Form.Select
            className="input"
            value={formValues.channel}
            onChange={(e) => setFormValueByKeyValue('channel', e.target.value)}
            required
            data-testid="channel"
            name="channel">
            <option value="">Select Channel</option>
            {allChannels?.channels?.map(({ id, channel_name }: Channel) => (
              <option key={id} value={id}>
                {channel_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group> */}

        {/* Min prep time */}
        <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="minPrepTime">
          <div className="me-3 w-30 label-container">
            <Form.Label>
              Minimum Preparation Time <br /> (hour)
            </Form.Label>
          </div>
          <div className="w-30">
            <Form.Control
              className="round-input input"
              value={formValues.minPrepTime}
              onChange={(e) => setFormValueByKeyValue('minPrepTime', e.target.value)}
              placeholder="eg. 6:00:00"
              data-testid="minPrepTime"
              name="minPrepTime"
              disabled={!formValues.isSameDay}
              required={formValues.isSameDay}
            />
          </div>
        </Form.Group>

        {/* Normal time slot from and to */}
        <Form.Group className="d-flex align-items-center gap-5  mb-3" controlId="timeSlot">
          <div className="me-3 w-30 label-container">
            <Form.Label>Normal time Slot</Form.Label>
          </div>
          <div className="d-flex">
            <Form.Group className="d-flex align-items-center" controlId="timeSlotFrom">
              <div className="label-container">
                <Form.Label className="sub-label me-3">From</Form.Label>
              </div>
              <Form.Control
                className="round-input login-input "
                value={formValues.from}
                onChange={(e) => {
                  setFormValueByKeyValue('from', e.target.value);
                }}
                as={CustomTimePickerFormControl}
                data-testid="from"
                name="form"
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center" controlId="timeSlotTo">
              <div className="label-container">
                <Form.Label className="sub-label me-3">To</Form.Label>
              </div>
              <Form.Control
                className="round-input login-input "
                value={formValues.to}
                onChange={(e) => {
                  setFormValueByKeyValue('to', e.target.value);
                }}
                as={CustomTimePickerFormControl}
                data-testid="to"
                name="to"
              />
            </Form.Group>
          </div>
        </Form.Group>

        <Form.Group
          className="d-flex align-items-center justify-content-start mb-3"
          controlId="isActive">
          <div className="me-3 w-30 label-container">
            <Form.Label>Is Active</Form.Label>
          </div>
          <Form.Check
            className="time-input"
            checked={formValues.isActive}
            onChange={(e) => setFormValueByKeyValue('isActive', e.target.checked)}
            type="checkbox"
            data-testid="isActive"
            name="isActive"
          />
        </Form.Group>
        {slotTemplateId && (
          <Form.Group
            className="d-flex align-items-center justify-content-start mb-3"
            controlId="updateSlots">
            <div className="me-3 w-30 label-container">
              <Form.Label>Update Existing Time Slots</Form.Label>
            </div>
            <Form.Check
              className="time-input"
              checked={formValues.updateSlots}
              onChange={(e) => setFormValueByKeyValue('updateSlots', e.target.checked)}
              type="checkbox"
              data-testid="updateSlots"
              name="updateSlots"
            />
          </Form.Group>
        )}
        <div className="mt-4 d-flex align-items-center justify-content-around">
          <div className="me-3 w-30 label-container">
            <Form.Label>&nbsp;</Form.Label>
          </div>
          <div className="d-flex align-items-center justify-content-end w-80 gap-3 mb-5">
            <LoadingButton
              label="Submit"
              loadingLabel="Submitting..."
              isDisabled={isUpdating || isCreating}
              isLoading={isUpdating || isCreating}
              testId={'submit'}
            />
            <Button
              type="button"
              variant="outline-primary"
              disabled={isUpdating || isCreating}
              onClick={onCancel}
              className="login-btn form-btn"
              data-testid="cancel">
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default withCookies(CreateUpdateSlotTemplate);
