import { gql } from '@apollo/client';

// Define query
export const GET_SLOT_TEMPLATES = gql`
  query ($accessToken: String!) {
    slotTypeAll(accessToken: $accessToken) {
      id
      from
      to
      maxCapacity
      storeCode
      isActive
      isSameDay
      minPrepTime
      sameDayCapacity
      channel {
        channel_name
      }
    }
  }
`;

export const GET_MASTER_SLOT_TEMPLATES = gql`
  query ($accessToken: String!) {
    fetchAllMasterSlots(accessToken: $accessToken) {
      id
      from
      to
      maxCapacity
      storeCode
      isActive
      isSameDay
      minPrepTime
      sameDayCapacity
      isSpecialSlot
      percentageForSM1
    }
  }
`;

export const GET_SLOT_TEMPLATE_BY_ID = gql`
  query ($id: ID!, $accessToken: String!) {
    slotTypeById(id: $id, accessToken: $accessToken) {
      id
      from
      to
      maxCapacity
      storeCode
      isActive
      isSameDay
      minPrepTime
      sameDayCapacity
      channel {
        id
        channel_name
      }
    }
  }
`;

export const GET_MASTER_SLOT_TEMPLATE_BY_ID = gql`
  query ($id: ID!, $accessToken: String!) {
    fetchMasterSlotById(id: $id, accessToken: $accessToken) {
      id
      isActive
      isSameDay
      isSpecialSlot
      from
      storeCode
      to
      percentageForSM1
      minPrepTime
      sameDayCapacity
      maxCapacity
    }
  }
`;

export const GET_SLOT_TEMPLATE_BY_STORE_CODE_AND_CHANNEL_ID = gql`
  query ($storeCode: String, $channelId: ID, $accessToken: String!) {
    slotTypeByStoreCodeAndChannel(
      storeCode: $storeCode
      channelId: $channelId
      accessToken: $accessToken
    ) {
      id
      from
      to
      maxCapacity
      storeCode
      isActive
      channelId
      isSameDay
      minPrepTime
      sameDayCapacity
      channel {
        id
        channel_name
      }
    }
  }
`;

export const GET_FILTERED_MASTER_SLOT_TEMPLATES = gql`
  query ($accessToken: String!, $storeCode: String, $isSpecialSlot: Boolean, $active: Boolean) {
    masterSlotsByStoreCodeAndType(
      storeCode: $storeCode
      isSpecialSlot: $isSpecialSlot
      active: $active
      accessToken: $accessToken
    ) {
      id
      from
      to
      maxCapacity
      storeCode
      isActive
      isSameDay
      minPrepTime
      sameDayCapacity
      isSpecialSlot
      percentageForSM1
    }
  }
`;
